<template>
    <v-app>        
        <div class="jh-mssg-list-area" id="scroll-target" v-scroll:#scroll-target="onScroll">
            <div class="is-mssg-divider">{{ historyDate }}</div>
            <div class="jh-mssg-list">
                <template v-for="(item, index) in chatList">
                    <div :class="item.SNDRCV_CD == 'RCV' ? 'from-left' : 'from-right'" :key="index">
                        <div v-if="item.SNDRCV_CD == 'RCV'" class="is-name">{{ item.CUSTOMER_NM }}</div>
                        <div v-if="item.SNDRCV_CD == 'SND'" class="is-name">{{ item.SEND_USER_NM }}</div>

                        <div v-if="(item.TYPE == 'IM' || item.TYPE == 'photo')" class="is-mssg">
                            <!--<img :src="setImageUrl(item)">-->
                            <img v-auth-image="getImageSrc(item)"/>
                            <div class="is-view-all" @click="showImgContent(item)">전체보기<i class="jh-icon-angle-right-xs"></i></div>
                        </div>

                        <div v-if="(item.TYPE != 'IM' && item.TYPE != 'photo')" class="is-mssg">{{ item.CONTENT }}
                            <div v-if="item.CONTENT.length > 1000" class="is-view-all" @click="showLongContent(item.CONTENT)">전체보기<i class="jh-icon-angle-right-xs"></i></div>
                        </div>
                        <div>
                            <!-- <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button> -->
                            <span class="is-mssg-time">{{ item.CHAT_DATE }}</span>
                        </div>
                    </div>
                </template>
            </div>
            <div class="is-mssg-divider">상담이 종료되었습니다.</div>
            
            <div id="scrollMe" ref="scrollToMe"></div>          
            <v-btn class="jh-btn is-to-bottom" v-if="ToBottomButton" @click="toBottomClick"><i class="jh-icon-arrow-down-lg"></i></v-btn>

            <!-- 장문 전체보기 -->
            <v-dialog v-model="dialogTotalMsg" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="jh-dialog-wrap has-body-scroll">            
                <div class="jh-dialog-header">
                    <h1>장문대화 전체보기</h1>
                    <div class="is-right">
                        <button type="button" class="jh-btn is-icon" title="닫기" @click="dialogTotalMsg=false"><i class="jh-icon-close-lg is-white"></i></button>
                    </div>
                </div>
                <div class="jh-dialog-body"><div class="jh-mssg-all">{{msg_all}}</div></div>
            </v-dialog>

            <!-- 이미지 전체보기 -->
            <v-dialog v-model="dialogTotalImage" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="jh-dialog-wrap has-body-scroll">                
                <div class="jh-dialog-header">
                    <h1>이미지 보기</h1>
                    <div class="is-right">
                        <button type="button" class="jh-btn is-icon" title="닫기" @click="dialogTotalImage=false"><i class="jh-icon-close-lg is-white"></i></button>
                    </div>
                </div>
                <div class="jh-dialog-body"><img v-auth-image="getImageSrc(image_all)" style="width: 650px;"/></div>                
            </v-dialog>
        </div>
    </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import axios from "axios";
import Vue from "vue";
import VueAuthImage from 'vue-auth-image';

Vue.use(VueAuthImage);

export default {
    name: "MENU_P101102", //name은 'MENU_' + 파일명 조합
    components: {
    },
    computed: {
    },
    mixins: [mixin],
    data: () => ({
        ToBottomButton: false,
        dialogTotalMsg: false,
        dialogTotalImage: false,
        msg_all: "",
        image_all: null,
        chatList: [],
        historyDate: "",
    }),

    methods: {
        showImgContent: function(item){
            console.log(`showImgContent call`)
            this.image_all = item;
            this.dialogTotalImage=true;
        },
        showLongContent: function(content){
            this.msg_all = content;
            this.dialogTotalMsg=true;
        },
        // 이미지 src 가져오기        
        getImageSrc: function(message)  {
            if(message == null) return;
            var strImageUrl = "";
            console.log("message  ", message);

            const token = window.sessionStorage.getItem("token");
            var authHeader = "bearer " + token;
            axios.defaults.headers.common['Authorization'] = authHeader;

            strImageUrl = `/api/file/chat/images/${message.FILE_GROUP_KEY}/${message.FILE_KEY}`;
            console.log("strImageUrl  ", strImageUrl);
            return strImageUrl;
        },
        toBottomClick() {
            const element = document.getElementById("scrollMe");       
            setTimeout(() => { 
                element.scrollIntoView({behavior: "smooth"});
                this.ToBottomButton = false;  
            }, 500);
        },     
        onScroll (e) {
            var scrollTop = e.target.scrollTop;
            var innerHeight = e.target.clientHeight;
            var scrollHeight = e.target.scrollHeight;
            if(scrollTop + innerHeight >= scrollHeight -70){
                this.ToBottomButton = false;
            }else{
                this.ToBottomButton = true;
            }
        },
        showToBottom (){            
            var container = document.querySelector("#scroll-target");
            var innerHeight = container.clientHeight;
            var scrollHeight = container.scrollHeight;
            if(innerHeight < scrollHeight){
                this.ToBottomButton = true;
            }else{
                this.ToBottomButton = false;
            }
        },
        
    },
    mounted() {    
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }  
        this.chatList = JSON.parse(localStorage['CHAT_DETAIL_LIST']);

         for (var i = 0; i < this.chatList.length; i++) {
            this.chatList[i].CONTENT = this.chatList[i].CONTENT.replaceAll("\\n", "\n");
            this.chatList[i].CONTENT = this.chatList[i].CONTENT.replaceAll("\\", "");
            this.chatList[i].CONTENT = this.chatList[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\n").replace(/\\/g, "");
         }


        console.log(this.chatList);
        //날짜 세팅
        var dateTime = new Date(this.chatList[0].IT_PROCESSING);
        this.historyDate = dateTime.getFullYear() + "년 " + (dateTime.getMonth() + 1) + "월 " + dateTime.getDate() + "일";       

        setTimeout(() => { 
            this.showToBottom ();
        }, 1000);
    },
};
</script>

<style>
</style>
